<template>
  <el-main>
    <el-form
      ref="form"
      :model="addForm"
      :rules="rules"
      label-width="200px"
      class="addForm"
    >
      <el-form-item label="名称：" prop="teacher">
        <el-input v-model="addForm.teacher"></el-input>
      </el-form-item>
      <el-form-item label="出勤单位：" prop="teacher_comefrom">
        <el-input v-model="addForm.teacher_comefrom"></el-input>
      </el-form-item>
      <el-form-item label="上岗时间：" prop="teacher_income_time">
        <el-date-picker
          v-model="teacher_income_time"
          @change="getTime"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="硬件设备：" prop="shipper_id">
        <el-select
          v-model="addForm.shipper_id"
          filterable
          placeholder="请选择设备"
          style="width: 100%"
        >
          <el-option
            v-for="item in shipperList"
            :key="item.id"
            :label="item.equipment_no"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="性别：">
        <el-radio-group v-model="addForm.sex">
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input v-model.number="addForm.age"></el-input>
      </el-form-item>
      <el-form-item label="现住址：" prop="address">
        <el-input v-model="addForm.address"></el-input>
      </el-form-item>
      <el-form-item label="电话：" prop="mobile">
        <el-input v-model.number="addForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="省市区：" class="city">
        <div class="box">
          <el-select
            v-model="addForm.province_id"
            placeholder="请选择"
            @change="(val) => sonArea(0, val,1)"
          >
            <el-option
              v-for="(item, index) in area"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.city_id"
            placeholder="请选择"
            @change="(val) => sonArea(1, val,1)"
          >
            <el-option
              v-for="(item, index) in cityArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.area_id"
            placeholder="请选择"
            @change="(val) => sonArea(2, val,1)"
          >
            <el-option
              v-for="(item, index) in areaArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="岗位：" prop="positioning">
        <el-input v-model="addForm.positioning"></el-input>
      </el-form-item>
      <el-form-item label="当前温度：" prop="current_temperature">
        <el-input v-model="addForm.current_temperature"></el-input>
      </el-form-item>
      <el-form-item label="最高温度：" prop="maximum_temperature">
        <el-input v-model="addForm.maximum_temperature"></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="addForm.remark"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="saveForm">保存</el-button>
        <router-link
          to="/home/LsolationPointMedicalStaff"
          style="margin-left: 12px"
        >
          <el-button>返回</el-button>
        </router-link>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      area: [],
      cityArr: [],
      areaArr: [],
      showAdd: !1,
      teacherList: [],
      shipperList: [],
      teacher_income_time: "",
      addForm: {
        teacher: "",
        teacher_comefrom: "",
        teacher_income_time: "",
        sex: 0,
        age: null,
        address: "",
        mobile: "",
        province_id: "",
        shipper_id: "",
        city_id: "",
        area_id: "",
        positioning: "",
        current_temperature: "",
        maximum_temperature: "",
        remark: "",
      },
      rules: {
        teacher: [{ required: true, message: "请输入名称", trigger: "blur" }],
        teacher_comefrom: [
          { required: true, message: "请输入工作岗位", trigger: "blur" },
        ],
        teacher_income_time: [
          { required: true, message: "请选择上岗时间", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入现住址", trigger: "blur" }],
        age: [
          { required: true, message: "请输入年龄", trigger: "blur" },
          { type: "number", message: "年龄必须为数字值", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入电话", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号码",
            trigger: "blur",
          },
        ],
        positioning: [
          { required: true, message: "请输入岗位", trigger: "blur" },
        ],
        current_temperature: [
          { required: true, message: "请输入当前温度", trigger: "blur" },
        ],
        maximum_temperature: [
          { required: true, message: "请输入最高温度", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        shipper_id: [
          { required: true, message: "请选择硬件设备", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      // this.getShipperList();
      this.handleInfo(id);
    } else {
      this.getArea();
      // this.getShipperList();
    }
  },
  methods: {
    async handleInfo(id) {
      await this.getArea();
      this.$axios.post(this.$api.teacher.info, { id: id }).then((res) => {
        if (res.code == 0) {
          let info = res.result.info;
          this.teacher_income_time = new Date(info.teacher_income_time * 1000);
          info.age = Number(info.age);
          info.mobile = Number(info.mobile);
          this.addForm = info;
          this.sonArea(0, info.province_id);
          this.sonArea(1, info.city_id);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取省市区信息
    getArea() {
      return new Promise((resolve) => {
        let that = this;
        this.$axios
          .get(this.$api.area, {
            tree: 1,
            level: 2,
          })
          .then((res) => {
            if (res.code == 0) {
              that.area = res.result.list;
              resolve();
            }
          });
      });
    },
    //添加隔离人员
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //编辑接口还没有
          let url = this.addForm.id
            ? this.$api.teacher.edit
            : this.$api.teacher.add;
          this.$axios.post(url, this.addForm).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.addForm.id ? "编辑成功" : "添加成功");
              this.$router.push("/home/LsolationPointMedicalStaff");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getTime(val) {
      if (val) this.addForm.teacher_income_time = val.getTime() / 1000;
    },
    // 三级联动选择地区
    sonArea(status, val,type) {
      if (status == 0) {
        let index = this.area.find((item) => item.id == val);
        this.cityArr = index._child;
        if (type) this.addForm.city_id = "";
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.id == val);
        this.areaArr = index._child;
        if (type) this.addForm.area_id = "";
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.id == val);
      }
    },
    //获取设备
    // getShipperList() {
    //   this.$axios.post(this.$api.shipper.list, { rows: 10000 }).then((res) => {
    //     if (res.code == 0) {
    //       this.shipperList = res.result.list;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-form-item {
    width: 750px;
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
